











































































































































































































































.split-input {
  .input {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}

